import * as React from "react"
import { Link } from "gatsby"
import touhoku from "../images/touhoku.jpg"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  //if (isRootPath) {
  header = (
    <Link to="/">
      <header
        style={{ backgroundImage: `url(${touhoku})` }}
        className="py-8 bg-[center_top_-4rem] md:bg-[center_top_-8rem] bg-cover bg-no-repeat hover:opacity-60"
      >
        <h1 className="text-2xl m-0 max-w-6xl mx-auto pl-4 text-white bg-[rgba(51,51,51,.8)] inline py-2 px-4">
          {title}
        </h1>
      </header>
    </Link>
  )
  //} else {
  //  header = (
  //    <div className="text-2xl mt-4 max-w-6xl mx-auto">
  //      <Link className="header-link-home" to="/">
  //        {title}
  //      </Link>
  //    </div>
  //  )
  //}

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      {header}
      <main className="max-w-5xl mx-auto">{children}</main>
      <footer className="text-center">
        <Link to="/" className="text-gray-400 font-bold">
          9mのパソコン日記
        </Link>
      </footer>
    </div>
  )
}

export default Layout
